(function () {
  'use strict';

  /* @ngdoc object
   * @name facilities.facility
   * @description
   *
   */
  angular
    .module('neo.home.facilities.facility', [ ]);
}());
